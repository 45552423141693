import React, { useEffect, useState } from "react"
import { handleError } from "../../../functions"
import { apiGetProductsByEvent, apiListProducts } from "../../../functions/api/product"
import { Product } from "../../../types"
import { ACTIVE_EVENT_ID } from "./../../../values/index"

const useEventProduct = (isIndex?: boolean) => {
  const [eventProducts, setEventProducts] = useState<Product[]>([])
  const [shouldScroll, setShouldScroll] = useState<boolean>(true)
  const [nextToken, setNextToken] = useState<string | null | undefined>(null)

  const onScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 400) {
      if (nextToken) {
        setShouldScroll(true)
      }
    }
  }

  useEffect(() => {
    if(!isIndex) {
      window.addEventListener("scroll", onScroll)
  
      return () => {
        window.removeEventListener("scroll", onScroll)
      }
    }
  }, [shouldScroll, nextToken])

  useEffect(() => {
    ;(async () => {
      if (shouldScroll) {
        try {
          const response = await apiGetProductsByEvent(
            ACTIVE_EVENT_ID,
            undefined,
            8,
            nextToken || undefined
          )
          if (response) {
            setEventProducts(p => {
              return p.concat(response!.items)
            })
            setNextToken(response!.nextToken)
            setShouldScroll(false)
          }
        } catch (error) {
          handleError(error)
        }
      }
    })()
  }, [shouldScroll])

  return {
    data: eventProducts,
  }
}

export default useEventProduct
