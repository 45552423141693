import { css } from "@emotion/react"
import React, { useState, useContext } from "react"
import { globalContext } from "../../../contexts/GlobalContext"
import { numberWithCommas } from "../../../functions"
import { Product } from "../../../types"
import { CLOUD_FRONT_URI } from "../../../values"
import { accent } from "../../../values/color"
import CountButton from "../../atom/CountButton"
import EventPercentageDisplay from "../../atom/EventPercentageDisplay"

interface EventProductProps {
  product: Product
  style?: React.CSSProperties
}

const root = css`
  position: relative;
  align-items: center;
  width: 228px;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  @media (max-width: 1024px) {
    width: calc(100vw / 3);
    padding: 10px;
  }

  @media (max-width: 600px) {
    width: 50vw;
  }
`

const productImage = css`
  width: 228px;
  border-radius: 114px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;

  @media (max-width: 1024px) {
    margin-bottom: 11.4px;
  }
`

const priceText = css`
  color: ${accent};
  font-size: 23px;
  font-weight: 700;
  @media (max-width: 1024px) {
    font-size: 16px;
  }
`

const nameText = css`
  display: -webkit-box;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 18px;
  height: 55px;
  margin-bottom: 15px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: 1024px) {
    height: 57px;
    padding-top: 3px;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 17px;
  }
`

const productPriceGray = css`
  font-size: 14px;
  font-weight: 500;
  text-decoration: line-through;
  color: rgba(0, 0, 0, 0.5);
  line-height: 18px;
  margin-left: 5px;
`

const wonText = css`
  font-size: 14px;
  font-weight: 500;
`

const EventProduct = ({ product, style }: EventProductProps) => {
  const { deliveryReducer, addConfirmModalHook } = useContext(globalContext)!
  const [count, setCount] = useState<number>(1)
  function handleCartButtonPress() {
    deliveryReducer.addCartItem(product, count)
    setCount(1)
    addConfirmModalHook.showModal({
      product: product,
      count: count,
      id: "0",
    })
  }

  function handleIncreaseButtonClick() {
    setCount(count + 1)
  }

  function handleDecreaseButtonClick() {
    if (count > 0) setCount(count - 1)
  }

  function handleDisplayPrice() {
    if (product.eventPrice) return numberWithCommas(product.eventPrice)
    else return numberWithCommas(product.price)
  }

  return (
    <div className="v-box" css={root} style={style}>
      <EventPercentageDisplay percentage={product.eventPercentage} />
      <img src={`${CLOUD_FRONT_URI}/${product.uri}`} css={productImage} />
      <div className="h-box" style={{ alignItems: "center", marginBottom: 6 }}>
        <span css={priceText}>
          {handleDisplayPrice()}
          <span css={wonText}>원</span>
        </span>
        <span css={productPriceGray}>{product.price}원</span>
      </div>

      <span css={nameText}>{product.name}</span>
      <CountButton
        count={count}
        onClickIncrease={handleIncreaseButtonClick}
        onClickDecrease={handleDecreaseButtonClick}
        forEvent
        onClickCartButton={handleCartButtonPress}
      />
    </div>
  )
}

export default EventProduct
