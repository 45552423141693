import { css } from "@emotion/react"
import React, { useEffect } from "react"

interface EventPercentageDisplayProps {
  percentage?: number
}

const container = css`
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background-color: #fa7d31;
  color: white;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    width: 52px;
    height: 52px;
  }
`

const containerPosition = css`
  position: absolute;
  left: 0px;
`

const percentageText = css`
  font-size: 14px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: -11px;
  
  @media (max-width: 1024px) {
    font-size: 13px;
    margin-top: 6px;
    margin-bottom: -9px;
  }
`

const discountText = css`
  font-size: 13px;
  font-weight: 400;
  padding-top: 3px;

  @media (max-width: 1024px) {
      
  }
`

const EventPercentageDisplay = ({ percentage }: EventPercentageDisplayProps) => {
  return (
    <div className="v-box" css={[container, containerPosition]} style={{
      backgroundColor: "#fa7d31"
      //percentage ? "#fa7d31" : "rgb(129,195,72)"
    }}>
      {percentage ? (
        <>
          <span css={percentageText}>{percentage}%</span>
          <span css={discountText}>할인</span>
        </>
      ) : (
        <>
          <span css={discountText}>기간제</span>
        </>
      )}
    </div>
  )
}

export default EventPercentageDisplay
