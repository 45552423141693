import React from "react"
import { MarginStyle } from "../../../types"

import "./style.scss"

interface PageTitleTextProps {
  style?: MarginStyle
  isNotBold?: boolean
  title: string
  color?: string
  id?: string
}

const PageTitleText = ({ style, isNotBold, title, color, id }: PageTitleTextProps) => {
  return (
    <span
      id={id}
      className="title-text"
      style={{ ...style, color: color, fontWeight: isNotBold ? 500 : 600 }}
    >
      {title}
    </span>
  )
}

export default PageTitleText
