import React, { createContext } from "react"
import { useMediaQuery } from "react-responsive"
import { MEDIA_QUERY } from "../../values"

export const indexContext = createContext({})

interface IndexContextProps {
  children: React.ReactNode
}

const IndexContextProvider = ({ children }: IndexContextProps) => {
  const isPc = useMediaQuery({
    query: MEDIA_QUERY,
  })

  return (
    <indexContext.Provider
      value={{
        isPc,
      }}
    >
      {children}
    </indexContext.Provider>
  )
}

export default IndexContextProvider
